<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    
    <div class="main voice-cloning-page">
      <!-- Hero Section -->
      <div class="hero-section">
        <div class="bubbles">
          <div class="bubble" v-for="n in 15" :key="n"></div>
        </div>
        
        <div class="hero-content">
          <h1 :data-text="$t('voice_cloning.heroTitle')">{{ $t('voice_cloning.heroTitle') }}</h1>
          <p class="hero-subtitle">{{ $t('voice_cloning.heroSubtitle') }}</p>
          <div class="cta-buttons">
            <router-link :to="'/' + $route.params.locale + '/pricing'" class="btn-primary">{{ $t('voice_cloning.viewPricing') }}</router-link>
          </div>
        </div>
      </div>

      <!-- Features Section -->
      <section class="features-section">
        <div class="feature-row">
          <div class="feature-text">
            <h3>{{ $t('voice_cloning.featureAI.title') }}</h3>
            <p>{{ $t('voice_cloning.featureAI.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('voice_cloning.featureAI.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-ai.png" alt="Advanced AI Technology">
          </div>
        </div>
        
        <div class="feature-row reverse">
          <div class="feature-text">
            <h3>{{ $t('voice_cloning.featureProcess.title') }}</h3>
            <p>{{ $t('voice_cloning.featureProcess.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('voice_cloning.featureProcess.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-process.png" alt="Cloning Process">
          </div>
        </div>

        <div class="feature-row">
          <div class="feature-text">
            <h3>{{ $t('voice_cloning.featureApps.title') }}</h3>
            <p>{{ $t('voice_cloning.featureApps.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('voice_cloning.featureApps.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-applications.png" alt="Voice Applications">
          </div>
        </div>
        
        <div class="feature-row reverse">
          <div class="feature-text">
            <h3>{{ $t('voice_cloning.featureSecurity.title') }}</h3>
            <p>{{ $t('voice_cloning.featureSecurity.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('voice_cloning.featureSecurity.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-security.png" alt="Security Features">
          </div>
        </div>
      </section>

      <!-- Integration Steps Section -->
      <section class="integration-steps-section">
        <h2>{{ $t('voice_cloning.howItWorks.title') }}</h2>
        <div class="steps-container">
          <div class="step-item" v-for="(step, index) in $t('voice_cloning.howItWorks.steps')" :key="index">
            <div class="step-number">{{ index + 1 }}</div>
            <h4>{{ step.title }}</h4>
            <p>{{ step.desc }}</p>
          </div>
        </div>
      </section>
    </div>

    <div class="footer">
      <voice-footer></voice-footer>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import VoiceHeader from '../components/VoiceHeader.vue'

const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooterIndex.vue'))

export default {
  name: 'VoiceCloning',
  components: {
    VoiceHeader,
    VoiceFooter
  },
  head() {
    return {
      title: this.$t('voice_cloning.headTitle'),
      keywords: this.$t('voice_cloning.headKeywords'),
      description: this.$t('voice_cloning.headDescription'),
      link: [
        { 
          rel: 'stylesheet', 
          href: '/ssr/css/voice_cloning.css',
          id: 'voice-cloning-style',
          media: 'all'
        }
      ]
    }
  }
}
</script>

<style scoped>
@import '/ssr/css/voice_cloning.css';

.container {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
}

.main {
  width: 100%;
}
</style>